export type OrgSortInfo = {
  value: 'numEvents' | 'name'
  text: string;
}

export const orgSorts: Array<OrgSortInfo> = [
  {
    value: 'name',
    text: 'Alphabetically'
  },
  {
    value: 'numEvents',
    text: 'Most Events'
  }
]

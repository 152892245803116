




































































import OrgBanner from '../components/OrgBanner.vue'
import Vue from "vue";
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { orgSorts } from '../model/OrgSort'

@Component({
  components: {
    OrgBanner
  },
  computed: {
    ...mapState([
      'activeTab',
      'browseOrgs',
      'browseOrgsSort'
    ])
  }
})
export default class Browse extends Vue {

  loading = true

  pendingLoad: Promise<number> | null = null

  private async created () {
    return this.load()
  }

  private async load () {
    this.loading = true
    await this.$store.dispatch('loadOrgs')
    this.loading = false
  }

  private get sortOptions () {
    return orgSorts
  }

  private async setSort (val) {
    this.loading = true
    await this.$store.dispatch('sortBrowseOrgs', val)
    this.loading = false
  }

}
